import 'isomorphic-fetch';
import {EEnvironment} from '../enums';
import { baseUrl } from './url.utils';

export const widgetDetailsGet = (id: string, env: EEnvironment): Promise<any> => {
    const url = baseUrl(id, env);

    return fetch(url)
        .then((response: any) => response.json())
        .then((response: any) => Promise.resolve(response));
};
