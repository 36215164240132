import * as React from 'react';
import {IMessengerForCardProps} from '../../../../../interfaces';
import {getIconClassName} from '../../../../../utils';
import MessengerIcon from '../../../../Icons/MessengerIcon';
import {useWidgetCardMessengerIconStyles} from '../../../widgetCard.styles';

const MessengerForCard = (props: IMessengerForCardProps) => {
    const {selectedMessenger, setSelectedMessenger, messenger} = props;
    const disabled = selectedMessenger !== messenger.id;
    const classes = useWidgetCardMessengerIconStyles(props);
    const iconClass = getIconClassName(props.messengerContainerBreakpoint, classes);

    return (
        <div
            key={messenger.id}
            className={classes.iconButton}
            onClick={() => setSelectedMessenger(messenger.id)}
        >
            <MessengerIcon
                config={props.config}
                chipWidget={false}
                messenger={messenger}
                disabled={disabled}
                className={iconClass}
            />
        </div>
    );
};

export default React.memo(MessengerForCard);
