export const VALID_IOS_VERSION = 11;
export const LINK_TEXT_PLACEHOLDER = '%link_text%';
export const LINK_NUMBER_PLACEHOLDER = '%link_number%';
export const WIDGET_ID_PLACEHOLDER = '%widget_id%';
export const APPLE_BLUE = '#007AFF';
export const IOS = 'iOS';
export const TOP_LEVEL_CONTAINER_ID = 'messengerpeople-widget-';
export const SPECIAL_CHARACTER_AND_REPLACE_VALUE = {
    '#': '%23',
};
