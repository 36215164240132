import './polyfills';

import React from 'react';
import {render} from 'react-dom';
import {TOP_LEVEL_CONTAINER_ID} from './lib/constants/const';
import {EEnvironment} from './lib/enums';
import MessengerPeopleWidget from './lib/MessengerPeopleWidget';

/**
 * this is the old mechanism of replacing the widgets. we have to keep this because of backwards compability
 */
const mountScripts = () => {
    // get all card and chip widget script tags
    // @ts-ignore
    window.mpWidgetCardScriptTags = Array.from(document.querySelectorAll('[data-widget=\'widgetCard\']'));
    // @ts-ignore
    window.mpWidgetCubeScriptTags = Array.from(document.querySelectorAll('[data-widget=\'widgetCube\']'));
    // @ts-ignore
    window.mpWidgetChipScriptTags = Array.from(document.querySelectorAll('[data-widget=\'widgetChip\']'));

    // @ts-ignore
    const tagsToRender = [...window.mpWidgetCardScriptTags, ...window.mpWidgetChipScriptTags, ...window.mpWidgetCubeScriptTags];

    for (const scriptTag of tagsToRender) {
        const mountingPoint = document.createElement('div');

        const {id, widget} = scriptTag.dataset;
        mountingPoint.id = `${TOP_LEVEL_CONTAINER_ID}${id}`;

        if (widget === 'widgetCard' || widget === 'widgetCube') {
            // replace script tag with container div ONLY CARD WIDGET
            scriptTag.parentNode.replaceChild(mountingPoint, scriptTag);
        } else {
            // for chip widget so the position: fixed does not get caught somewhere on the website
            document.body.appendChild(mountingPoint);
            scriptTag.parentNode.removeChild(scriptTag);
        }

        const idFromUrl = new URL(window.location.href).searchParams.get('id');

        if (!widget && !idFromUrl) {
            console.error('The property data-widget is missing in the Messengerpeople widget script tag. Without this property the widget cannot be mounted.');
        }

        const idToUse = id ? id : idFromUrl;

        if (!idToUse) {
            console.error('The Messengerpeople widget cannot be mounted without the unique id.');
        }

        // tslint:disable-next-line:max-line-length
        render(<MessengerPeopleWidget id={idToUse} style={scriptTag.dataset.style}
                                      env={scriptTag.dataset.env}/>, mountingPoint);
    }

    // @ts-ignore
    delete window.mpWidgetChipScriptTags;
    // @ts-ignore
    delete window.mpWidgetCardScriptTags;
};

// when localhost, render the widget for developing purposes if not mount the scripts
if (process.env.NODE_ENV === 'production') {
    // if not localhost, then replace the script tags with instances of the widget
    mountScripts();
} else {
    const rootEl = document.getElementById('root');

    // all the test cases
    const cardId = '1230460ace320846ea0.15743150';
    const chipId = '113275ffe977e8b3434.13111149';
    const cubeId = '105665face700b56962.67625487';
    const idFromUrl = new URL(window.location.href).searchParams.get('id');

    // just enable the testcase you´d like
    render(
        // <NewFbMessengerIcon disabled={true} />
        <MessengerPeopleWidget
            // for script case
            // id={cardId}
            id={cardId}
            // id={'113626001566dca1f97.41262721'}
            // directlink and iframe case
            // id={idFromUrl}
            // mcp widget editor case
            //  config={sampleConfig}
            // live env is not cached and goes to rest.
            env={EEnvironment.Dev}
        />
        ,
        rootEl,
    );
}
