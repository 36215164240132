import {createStyles} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';

export const useWidgetCubeMessengerChipStyles = makeStyles(
    createStyles({
        chip: {
            display: 'flex',
            cursor: 'pointer',
            margin: 8,
            border: (props: any) => `2px solid ${props.messengerEntry.color}`,
            borderRadius: 20,
            alignItems: 'center',
            minWidth: 148,
            height: 38,
            position: 'relative',
            textAlign: 'center',
        },
        typo: {
            width: 20,
            flex: 3,
            marginLeft: 42,
            fontSize: 12,
        },
        icon: {
            marginTop: 4,
            position: 'absolute',
            left: -6,
        },
        iconIOS: {
            position: 'absolute',
            left: -6,
        },
    }),
);

export const useWidgetCubeMessengerChipSelectionStyles = makeStyles(
    createStyles({
        cubeMessengerSelection: {
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bottom: 0,
            width: '100%',
            justifyContent: 'center',
        },
    }),
);

export const useWidgetCubeSelectionPageStyles = makeStyles(
    createStyles({
        cubeSelectionContainer: {
            height: '100%',
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
        },
        title: {
            textAlign: 'center',
            fontSize: 24,
            marginTop: 8,
            marginBottom: 8,
        },
        body: {
            fontSize: 12,
            marginRight: 24,
            marginLeft: 24,
        },
    }),
);

export const useWidgetCubeStyles = makeStyles(
    createStyles({
        cube: {
            position: 'relative',
            borderRadius: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 16,
        },
    }),
);

export const useWidgetCubeForwardingPageStyle = makeStyles(
    createStyles({
        container: {
            borderRadius: 20,
            width: '100%',
            height: '90%',
            display: 'flex',
            justifyContent: 'center',
            position: 'relative',
            alignItems: 'center',
            flexDirection: 'column',
        },
        icon: {
            position: 'absolute',
            top: -24,
        },
        arrow: {
            position: 'absolute',
            left: 8,
            top: 8,
        },
        typo: {
            marginTop: 44,
            fontSize: 12,
            maxHeight: 145,
            overflowWrap: 'break-word',
            width: '85%',
        },
        protectionStatementContainer: {
            flex: 1,
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        protectionStatement: {
            flex: 1,
            marginTop: 12,
            marginBottom: 8,
            width: '85%',
            paddingRight: 4,
        },
        buttonContainer: {
            position: 'absolute', bottom: 8,
        },
        button: {
            marginBottom: 16,
            borderRadius: 40,
        },
    }),
);
