import React, {useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';

import {SpeedDial, SpeedDialAction, SpeedDialIcon} from '@material-ui/lab';
import {IMessenger} from '../../../interfaces';
import {TCheckBoxEventFunction} from '../../../types';
import {getDisabled, getMessengerWithPriority, redirectMessenger} from '../../../utils';
import {ChatIcon} from '../../Icons/ChatIcon';
import MessengerIcon from '../../Icons/MessengerIcon';
import {useWidgetChipSpeedDialStyles} from '../widgetChip.styles';
import ChipDataProtectionStatement from './ChipDataProtectionStatement';
import NotificationBubble from './NotificationBubble';
import {IChipWidgetProps} from '../WidgetChip';

export interface IChipSpeedDialProps extends IChipWidgetProps {
    notificationBubble: boolean;
}

const ChipSpeedDial = (props: IChipSpeedDialProps) => {
    const classes = useWidgetChipSpeedDialStyles(props);
    const {config, configMode, notificationBubble} = props;

    const [open, setOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const onChange: TCheckBoxEventFunction = (event, checked) => setCheckboxChecked(checked);

    const handleStartChatClick = (messenger: IMessenger): void => {
        redirectMessenger(messenger, config);
        setOpen(false);
    };

    const disabled = getDisabled(config, checkboxChecked);
    const messengerWithPriority = getMessengerWithPriority(config);

    if (!config.privacy_check && Object.keys(config.messengers).length === 1) {
        const onlyMessenger = Object.values(config.messengers)[0];

        return (
            <>
                <SpeedDial
                    className={classes.speedDial}
                    FabProps={{className: classes.seedDialButtonSingleMessenger}}
                    style={{right: 0}}
                    ariaLabel="MessengerPeople Widget"
                    icon={
                        <MessengerIcon
                            chipWidget
                            config={config}
                            className={classes.iconForSpecialConfig}
                            messenger={onlyMessenger}
                            disabled={false}
                        />
                    }
                    open={false}
                    onClick={() => redirectMessenger(onlyMessenger, config)}
                />
                <NotificationBubble enabled={notificationBubble} clickedWidget={clicked} configMode={configMode} />
            </>
        );
    }

    return (
        <>
            <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                <SpeedDial
                    className={classes.speedDial}
                    FabProps={{className: classes.speedDialButton}}
                    ariaLabel="MessengerPeople Widget"
                    icon={config.settings.chipBackgroundImage?.value ? <span className={classes.speedDialImage} /> : <SpeedDialIcon
                        openIcon={<CloseIcon fontSize={'inherit'} />}
                        icon={<ChatIcon style={{marginBottom: 4}} fontSize={'inherit'} />}
                    />
                    }
                    open={open}
                    onClick={() => {
                        setOpen(!open);
                        setClicked(true);
                    }}
                >
                    {messengerWithPriority.map((messenger: IMessenger) => {
                        return (
                            <SpeedDialAction
                                className={classes.speedDialAction}
                                key={messenger.name}
                                icon={
                                    <MessengerIcon
                                        chipWidget
                                        config={config}
                                        className={classes.icon}
                                        messenger={messenger}
                                        disabled={disabled}
                                    />
                                }
                                tooltipTitle={messenger.name}
                                onClick={() => handleStartChatClick(messenger)}
                                FabProps={{disabled}}
                                title={messenger.name}
                            />
                        );
                    })}
                </SpeedDial>
                <ChipDataProtectionStatement
                    chipOpen={open}
                    config={config}
                    onChange={onChange}
                    checkboxChecked={checkboxChecked}
                    configMode={configMode}
                />
            </div>
            <NotificationBubble
                enabled={notificationBubble}
                clickedWidget={clicked}
                configMode={configMode}
            />
        </>
    );
};

export default React.memo(ChipSpeedDial);
