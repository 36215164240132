import * as React from 'react';
import {useWidgetCardMessengerContainerStyles} from '../../../widgetCard.styles';

interface IProps {
    messengerToShow: any[];
    children: React.ReactNode;
}

const WidgetCardMessengerContainer = (props: IProps) => {
    const classes = useWidgetCardMessengerContainerStyles(props);
    const {messengerToShow} = props;

    // we need flex for the widgets with only 1 messenger
    const className = messengerToShow.length === 1
        ? classes.messengerContainer
        : undefined;

    return (
        <div className={className}>
            {props.children}
        </div>
    );
};

export default React.memo(WidgetCardMessengerContainer);
