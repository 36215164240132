import {ICommonColors} from '../interfaces';

export enum ELanguage {
    DE = 'de',
    EN = 'en',
    ES = 'es',
}

export enum ECustomColor {
    Link = '#333333',
    Gray = '#EEEEEE',
    White = '#FFFFFF',
    Black = '#000000',
    LinkVisited = '#555555',
    ShadowSurface = 'darkgrey',
}

export enum ApplePlattforms {
    IPhone = 'iphone',
    IPad = 'ipad',
    IOs = 'ios',
    MacOs = 'macos',
}
