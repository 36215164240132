import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const svgStyle = {
    fillRule: 'evenodd',
};

export const ChatIcon = (props: any) => {
    return (
        <SvgIcon viewBox='0 0 400 400' {...props} style={{ ...svgStyle, ...props.style }}>
            <path d='M187.991,141.159c11.675,0.152 23.046,5.307 31.082,13.343c8.036,8.339 12.888,19.256 12.888,31.234l0,62.772c0,11.978 -4.852,23.198 -13.191,31.234c-8.036,8.036 -19.408,13.04 -31.386,13.04l-60.497,0l-39.725,45.789c-1.517,1.668 -3.673,2.881 -6.52,2.881c-4.094,-0.151 -6.672,-3.032 -7.126,-7.126l0.151,-41.544c-11.675,-0.152 -22.895,-5.156 -30.931,-13.495c-8.036,-8.036 -12.736,-19.104 -12.736,-31.082l0,-62.621c0,-11.978 4.852,-23.046 13.191,-31.385c8.036,-8.036 19.407,-13.04 31.386,-13.04l113.414,0Zm-19.952,-15l0,-0.395c0,-11.978 4.852,-22.895 12.888,-31.234c8.036,-8.036 19.408,-13.191 31.082,-13.343l113.414,0c11.978,0 23.35,5.004 31.386,13.04c8.339,8.339 13.191,19.408 13.191,31.386l0,62.62c0,11.978 -4.7,23.046 -12.736,31.082c-8.036,8.34 -19.256,13.343 -30.931,13.495l0.151,41.544c-0.454,4.094 -3.032,6.975 -7.126,7.127c-2.847,0 -5.003,-1.213 -6.52,-2.881l-39.725,-45.79l-26.115,0c0.025,-15.816 0.012,-31.633 -0.038,-47.449c-0.124,-13.135 -4.696,-25.917 -12.683,-36.188c-1.747,-2.247 -4.048,-4.721 -4.5,-5.181c-10.838,-11.039 -25.785,-17.469 -41.219,-17.826c-0.149,-0.002 -0.297,-0.004 -0.446,-0.007l-20.073,0Z' />
        </SvgIcon>
    );
};
