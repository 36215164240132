import React from 'react';
import {IDataProtectionStatementProps} from '../../interfaces';
import DataProtectionCheckBox from './components/DataProtectionCheckBox';
import DataProtectionTextSplitter from './components/DataProtectionTextSplitter';

const DataProtectionStatement = (props: IDataProtectionStatementProps) => {
    const {config, checkboxChecked, onChange, align} = props;

    const privacyCheck = Boolean(props.config.privacy_check);

    if (!privacyCheck) {
        return null;
    }

    return (
        <span style={{display: 'flex'}}>
            <DataProtectionCheckBox
                config={config}
                onChange={onChange}
                checkboxChecked={checkboxChecked}
            />
            <DataProtectionTextSplitter config={config} align={align} />
        </span>
    );
};

export default DataProtectionStatement;
