import React from 'react';
import {IMessenger, IWidgetCardMessengerContainerProps} from '../../../../interfaces';
import {getMessengerWithPriority} from '../../../../utils';
import MessengerForCard from './components/MessengerForCard';
import WidgetCardMessengerContainer from './components/WidgetCardMessengerContainer';

const WidgetCardMessenger = (props: IWidgetCardMessengerContainerProps) => {
    const messengerToShow = getMessengerWithPriority(props.config);

    return (
        <WidgetCardMessengerContainer
            messengerToShow={messengerToShow}
        >
            {messengerToShow.map((messenger: IMessenger) =>
                <MessengerForCard
                    key={messenger.id}
                    config={props.config}
                    messenger={messenger}
                    setSelectedMessenger={props.setSelectedMessenger}
                    selectedMessenger={props.selectedMessenger}
                    messengerContainerBreakpoint={props.messengerContainerBreakpoint}
                />,
            )}
        </WidgetCardMessengerContainer>
    );
};

export default WidgetCardMessenger;
