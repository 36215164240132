import MobileDetect from 'mobile-detect';
import {VALID_IOS_VERSION} from '../constants/const';

export const isClientIosWithViableVersion = () => {
    const md = new MobileDetect(window.navigator.userAgent);

    /**
     * this combination is used in https://hgoebl.github.io/mobile-detect.js/check/
     * to determinate the right combination for the ios version required from apple in ticket [571]
     */
    const isIos = md.is('iOS');
    const version = md.version('Version');

    const isVersionValid = version >= VALID_IOS_VERSION;

    return isIos && isVersionValid;
};
