import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';
import {WHITE} from '../../../constants';
import {ICustomIconProps} from '../../../interfaces';

export const NotifyIcon = (props: ICustomIconProps) => {
    const path =
        'M416.748,1140.6l-149.562,0c-137.576,0 -249.27,-111.694 -249.27,-249.27l0,-498.539c0,-137.575 111.694,-249.269 249.27,-249.27l152.634,0l125.51,251.021c7.755,15.51 25.157,23.653 42.034,19.669c16.877,-3.985 28.8,-19.05 28.8,-36.391l0,-234.299l118.178,0c-1.073,9.152 -1.625,18.462 -1.625,27.9c0,130.993 106.349,237.342 237.341,237.342c15.362,0 30.385,-1.462 44.937,-4.256l0,486.823c0,137.576 -111.693,249.269 -249.27,249.27l-152.633,0l-125.511,-251.02c-7.755,-15.51 -25.156,-23.653 -42.033,-19.669c-16.877,3.984 -28.8,19.049 -28.8,36.39l0,234.299Z';

    if (props.chipWidget) {
        const containerStyle = {
            backgroundColor: props.color,
            borderRadius: 90,
            height: props.chipWidget ? 40 : 50,
            width: props.chipWidget ? 40 : 50,
        };
        return (
            <div style={containerStyle}>
                <SvgIcon viewBox='-420 -320 1900 1900' className={props.className}>
                    <g fill='none' fillRule='evenodd'>
                        <path fill={WHITE} d={path}/>
                        <circle cx='970.058' cy='171.423' r='162.025' fill={WHITE}/>
                    </g>
                </SvgIcon>
            </div>
        );
    }

    return (
        <SvgIcon
            style={{backgroundColor: props.color, borderRadius: 90}}
            viewBox='-420 -320 1900 1900'
            className={props.className}
        >
            <g fill='none' fillRule='evenodd'>
                <path fill={WHITE} d={path}/>
                <circle cx='970.058' cy='171.423' r='162.025' fill={WHITE}/>
            </g>
        </SvgIcon>
    );
};
