import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import React from 'react';
import {EWidgetTypeId} from '../../../enums';
import {IWidgetConfig} from '../../../interfaces';
import {TCheckBoxEventFunction} from '../../../types';
import {getDataProtectionCheckBoxStyles, isChipWidget} from '../../../utils';
import {
    useDataProtectionCheckboxStylesForCard,
    useDataProtectionCheckboxStylesForChip, useDataProtectionCheckboxStylesForCube,
} from '../dataProtectionStatement.styles';

interface IProps {
    config: IWidgetConfig;
    onChange: TCheckBoxEventFunction;
    checkboxChecked: boolean;
}

const DataProtectionCheckBox = (props: IProps) => {
    const {onChange, checkboxChecked} = props;

    const classes = getDataProtectionCheckBoxStyles(props.config, props);

    return (
        <span className={classes.checkboxContainer} onClick={() => onChange(undefined, !checkboxChecked)}>
            {checkboxChecked
                ? <CheckBoxIcon className={classes.checkboxSVG} color={'inherit'}/>
                : <CheckBoxOutlineBlankIcon className={classes.checkboxSVG} color={'inherit'}/>
            }
        </span>
    );
};

export default DataProtectionCheckBox;
