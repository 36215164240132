import Typography from '@material-ui/core/Typography';
import React from 'react';
import {useWidgetCardContentContainerStyles} from '../../../widgetCard.styles';

interface IProps {
    title: string;
    body: string;
    channelName: string;
}

const WidgetCardTextContainer = (props: IProps) => {
    const {title, body} = props;
    const classes = useWidgetCardContentContainerStyles(props);

    return (
        <div className={classes.textContainer}>
            <Typography variant="subtitle2" gutterBottom color="inherit">
                {title}
            </Typography>
            <Typography variant="body2" className={classes.bodyText} color="inherit" gutterBottom>
                {body}
            </Typography>
        </div>
    );
};

export default React.memo(WidgetCardTextContainer);
