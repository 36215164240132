import {createGenerateClassName} from '@material-ui/styles';
import {GenerateId} from 'jss';
import {EWidgetSettingName} from '../enums';
import {EWidgetCardCustomBreakpoint} from '../enums/component.enums';
import {TWidgetSettings} from '../types';

export const generateClassName = (prefix: string): GenerateId => {
    return createGenerateClassName({
        productionPrefix: prefix,
        disableGlobal: true,
    });
};

export const getWidgetSettingValue = (settings: TWidgetSettings, key: EWidgetSettingName): string | undefined =>
    settings && settings[key] && settings[key].value;

/**
 * returns false when no card widget was found
 */
export const getWidgetCardCustomBreakpoints = (id: string) => {
    const widgetCard = document.getElementById(id);

    if (!widgetCard) {
        return false;
    }

    const width = widgetCard.offsetWidth;

    if (width > 400) {
        return EWidgetCardCustomBreakpoint.Large;
    }

    if (width < 400 && width > 350) {
        return EWidgetCardCustomBreakpoint.Medium;
    }

    if (width < 350 && width > 300) {
        return EWidgetCardCustomBreakpoint.Small;
    }

    return EWidgetCardCustomBreakpoint.ExtraSmall;
};

export const getIconClassName = (messengerContainerBreakpoint: EWidgetCardCustomBreakpoint, classes) => {
    if (messengerContainerBreakpoint === EWidgetCardCustomBreakpoint.Large) {
        return classes.iconLarge;
    }
    if (messengerContainerBreakpoint === EWidgetCardCustomBreakpoint.Medium) {
        return classes.iconMedium;
    }
    if (messengerContainerBreakpoint === EWidgetCardCustomBreakpoint.Small) {
        return classes.iconSmall;
    }

    return classes.iconExtraSmall;
};

export const getStylesFromProps = (styleStringFromDataSet: string) => {
    try {
        if (!styleStringFromDataSet) {
            return {};
        }
        const split = styleStringFromDataSet.split(';');

        const styleObject = split.reduce(
            (accumulator: any, currentValue: string): any => {
                if (!currentValue.includes(':')) {
                    return accumulator;
                }

                const splitEntry = currentValue.split(':');
                const key = splitEntry[0].trim();
                const value = splitEntry[1].trim();

                accumulator[key] = value.split('\'').join('');

                return accumulator;
            },
            {},
        );

        return styleObject;
    } catch (e) {
        console.error(e);
    }
};
