import {createMuiTheme, Theme} from '@material-ui/core';
import {PaletteOptions} from '@material-ui/core/styles/createPalette';
import {TypographyOptions} from '@material-ui/core/styles/createTypography';
import {EWidgetSettingName} from '../enums';
import {IWidgetConfig, IWidgetTheme} from '../interfaces';
import {getWidgetSettingValue} from '../utils';

const BLACK_56 = 'rgba(0, 0, 0, 0.56)';
const WHITE_87 = 'rgba(255, 255, 255, 0.87)';

const palette: PaletteOptions = {
    primary: {
        contrastText: WHITE_87,
        dark: '#009469',
        main: '#09C697',
    },
    secondary: {
        contrastText: BLACK_56,
        main: '#eeeeee',
    },
};

const typography: TypographyOptions = {
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
};

const breakpoints = {
    values: {
        xs: 0,
        // the smallest of them all (phones)
        sm: 320,
        md: 600,
        lg: 900,
        xl: 1200,
    },
};

export const getWidgetTheme = (config: IWidgetConfig): IWidgetTheme => {
    return {
        ...theme,
        widgetCustomSettings: {
            [EWidgetSettingName.BackgroundColorLeft]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.BackgroundColorLeft),
            [EWidgetSettingName.BackgroundColorRight]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.BackgroundColorRight),
            [EWidgetSettingName.ButtonColor]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.ButtonColor),
            [EWidgetSettingName.ButtonTextColor]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.ButtonTextColor),
            [EWidgetSettingName.ChipColor]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.ChipColor),
            [EWidgetSettingName.ChipTextColor]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.ChipTextColor),
            [EWidgetSettingName.TextColor]:
                getWidgetSettingValue(config.settings, EWidgetSettingName.TextColor),
        },
    };
};

const theme: Theme = createMuiTheme({
    palette,
    typography,
    breakpoints,
});

// impossible to type and only way to remove box shadows globally
// @ts-ignore
// theme.shadows = ['none'];
