export enum EEnvironment {
    Staging,
    Dev,
    Live,
    CDN,
}

export enum EEnvironmentName {
    Staging = 'reststaging',
    Dev = 'restdev',
    Live = 'rest',
}
