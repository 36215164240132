import MobileDetect = require('mobile-detect');
import React, {ReactNode} from 'react';
import {
    useDataProtectionCheckboxStylesForCard,
    useDataProtectionCheckboxStylesForChip, useDataProtectionCheckboxStylesForCube,
    useDataProtectionTextSplitterStylesForCard,
    useDataProtectionTextSplitterStylesForChip, useDataProtectionTextSplitterStylesForCube,
} from '../components/DataProtectionStatement/dataProtectionStatement.styles';
import {MESSENGER_TO_REPLACE} from '../constants';
import {IOS, VALID_IOS_VERSION} from '../constants/const';
import {EMessengerId, EPrivacyCheck, EWidgetSettingName, EWidgetTypeId} from '../enums';
import {IMessenger, IWidgetConfig, IWidgetSetting} from '../interfaces';
import {TMobileOS, TWidgetSettings} from '../types';

export const getButtonText = (config: IWidgetConfig, messengerId: EMessengerId): string => {
    if (messengerId === EMessengerId.None) {
        return '';
    }

    const buttonTextConfig = config.messengers[messengerId].button_text;
    let buttonText;

    if (typeof buttonTextConfig === 'string') {
        buttonText = buttonTextConfig;
    }

    if (config.language && buttonTextConfig[config.language]) {
        buttonText = buttonTextConfig[config.language];
    }

    if (!buttonText) {
        return 'Start Conversation';
    }

    return buttonText.replace(MESSENGER_TO_REPLACE, config.messengers[messengerId].name);
};

export const renderRectangle = (messengerId: EMessengerId): ReactNode => {
    let rectProps = {x: '4.5', y: '4.5', width: '16', height: '16'};

    switch (messengerId) {
        case EMessengerId.Facebook:
            rectProps = {x: '4', y: '4', width: '16.36', height: '16.27'};
            break;
        case EMessengerId.WhatsApp:
        case EMessengerId.WhatsAppBusiness:
            rectProps = {x: '3.52', y: '4.51', width: '16', height: '15'};
            break;
        case EMessengerId.Telegram:
            rectProps = {x: '4.5', y: '5.5', width: '14', height: '13'};
            break;
        case EMessengerId.Viber:
            rectProps = {x: '4.78', y: '4.78', width: '15', height: '16'};
            break;
        case EMessengerId.WN:
            rectProps = {x: '4.36', y: '4.36', width: '15.36', height: '15.27'};
            break;
        default:
            break;
    }

    return <rect {...rectProps} fill='#FFF'/>;
};

export const getDisabled = (config: IWidgetConfig, checkboxChecked: boolean): boolean => {
    if (+config.privacy_check === 1) {
        return !checkboxChecked;
    }

    return false;
};

export const reduceWidgetConfigSettings = (settings: TWidgetSettings) => Object.values(settings).reduce(
    (acc: any, setting: IWidgetSetting) => {
        acc[setting.name] = setting.value;
        return acc;
    },
    {},
);

export const didSettingsValuesChangeInWidgetConfig =
    (prevConfig: IWidgetConfig, currentConfig: IWidgetConfig): boolean => {
        const prevSettings = prevConfig.settings;
        const currentSettings = currentConfig.settings;

        if (!prevSettings || !currentSettings) {
            return false;
        }

        const prevSettingsKeyValues = reduceWidgetConfigSettings(prevSettings);
        const currentSettingsKeyValues = reduceWidgetConfigSettings(currentSettings);

        let didSettingsChange = false;

        // for the settings
        Object.entries(currentSettingsKeyValues).forEach(
            (keyValue: any) => {
                const key = keyValue[0];
                const value = keyValue[1];

                const oldValue = prevSettingsKeyValues[key];

                if (oldValue !== value) {
                    didSettingsChange = true;
                }
            },
        );

        if (prevConfig.messengers !== currentConfig.messengers) {
            didSettingsChange = true;
        }

        // for the other dynamic values that could are part of the live preview
        if (currentConfig.title !== prevConfig.title) {
            didSettingsChange = true;
        }

        if (currentConfig.body !== prevConfig.body) {
            didSettingsChange = true;
        }

        if (currentConfig.cubeHeight !== prevConfig.cubeHeight) {
            didSettingsChange = true;
        }
        if (currentConfig.cubeWidth !== prevConfig.cubeWidth) {
            didSettingsChange = true;
        }

        return didSettingsChange;
    };

export const getPrivacyCheckNeeded = (config: IWidgetConfig) => Number(config.privacy_check) === EPrivacyCheck.Enabled;

/**
 * returns a map of the messengers with notify on first place
 * if configured && deviceIsAbleTo only iMessage will show
 *
 * @param config
 */
export const getMessengerWithPriority =
    (config: IWidgetConfig): IMessenger[] => {
        const {messengers} = config;
        const mobileOS = getMobileOperatingSystem();

        if (Object.keys(messengers).length < 1) {
            return [];
        }

        const isAbcIconOnly = isWidgetAppleIconOnly(config);

        if (mobileOS !== 'Android' && isAbcIconOnly && deviceHasValidIOSVersion()) {
            return [messengers[EMessengerId.IMessage]];
        }

        const notify = messengers[EMessengerId.InstaAndNotify];

        const messengersWithoutNotify =
            Object.values(messengers).filter((messenger: IMessenger) => messenger.id !== EMessengerId.InstaAndNotify);

        const messengerWithoutSequence = notify ? [notify, ...messengersWithoutNotify] : [...messengersWithoutNotify];
        const messengerSequence =
            Object.values(messengers)
                .filter((messenger: IMessenger) => Boolean(messenger.sequence));

        if (messengerSequence.length < 1) {
            return spliceMessengerInConditionOfOS(messengerWithoutSequence);
        }

        const messengerWithSequence = [];
        messengerWithoutSequence.forEach((messenger: IMessenger) => {
            if (messenger.sequence) {
                messengerWithSequence.splice(messenger.sequence, 0, messenger);
            } else {
                messengerWithSequence.push(messenger);
            }
        });

        messengerWithSequence.sort((a, b) => {
            if (a.sequence && b.sequence) {
                return a.sequence - b.sequence;
            }
            return 0;
        });

        return spliceMessengerInConditionOfOS(messengerWithSequence);
    };

export const getInitialMessenger = (config: IWidgetConfig): EMessengerId => {
    const isAbcOnly = isWidgetAppleIconOnly(config);
    const messenger = config.messengers;

    if (!isAbcOnly) {
        const messengerArray = Object.values(messenger);

        if (messengerArray) {
            const sequenceOne = messengerArray.find((entry) => entry.sequence === 1);
            if (sequenceOne) {
                return sequenceOne.id;
            }
        }

        return messengerArray && messengerArray[0]
            ? messengerArray[0].id
            : EMessengerId.None;
    }

    return messenger[EMessengerId.IMessage].id || EMessengerId.None;
};

export const isChipWidget = (config: IWidgetConfig) => config.widget_type_id === EWidgetTypeId.Chip;

// test string for riccardos iphone
// tslint:disable-next-line:max-line-length
// const riccardosIphone = 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1';
// tslint:disable-next-line:max-line-length
// const mikesIphone = 'Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.2 Mobile/15E148 Safari/604.1';

export const deviceHasValidIOSVersion = (): boolean => {
    const md = new MobileDetect(navigator.userAgent);
    const os = md.os() || '';
    const version = md.version(IOS);

    if (os.includes(IOS) && version >= VALID_IOS_VERSION) {
        return true;
    }

    return false;
};

const areMessengersValid = (config: IWidgetConfig) => {
    if (!config.hasOwnProperty('messengers')) {
        return false;
    }

    const {messengers} = config;

    if (!messengers) {
        return false;
    }

    const length = Object.keys(messengers).length;

    return length >= 1;
};

export const isWidgetAppleIconOnly = (config: IWidgetConfig): boolean =>
    config.settings[EWidgetSettingName.ABC_ICON_ONLY]
        ? Boolean(config.settings[EWidgetSettingName.ABC_ICON_ONLY].value)
        : false;

export const isWidgetCardConfigValid = (config: IWidgetConfig): boolean => {
    return areMessengersValid(config);
};

export const isWidgetChipConfigValid = (config: IWidgetConfig): boolean => {
    return areMessengersValid(config);
};

export const getInitialMessengerFromConfig = (config: IWidgetConfig) => {
    const {messengers} = config;

    const messengerArray = Object.values(messengers);

    return messengerArray && messengerArray[0]
        ? messengerArray[0].id
        : EMessengerId.None;
};

export const getMobileOperatingSystem = (): TMobileOS | false => {
    // @ts-ignore-next-line
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return 'Windows Phone';
    }

    if (/android/i.test(userAgent)) {
        return 'Android';
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'iOS';
    }

    return false;
};

export const spliceMessengerInConditionOfOS = (messenger: IMessenger[]) => {
    const mobileOS = getMobileOperatingSystem();

    // BMW will kein apple messenger, wenn android
    if (mobileOS === 'Android') {
        return messenger.filter((entry) => entry.id !== EMessengerId.IMessage);
    }

    return messenger;
};

export const getSettingFromConfigByName =
    (config: IWidgetConfig, settingName: EWidgetSettingName) => {
        const setting = config.settings[settingName];
        if (!setting) {
            return;
        }

        return setting.value;
    };

export const hexToRgb = (hex) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    const config = result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;

    if (!config) {
        return null;
    }

    return `rgba(${config.r}, ${config.g}, ${config.b}, 0.5)`;
};

export const getWidthAndHeightForCube = (config?: IWidgetConfig) => {
    if (
        !config.settings.cubeWidth
        || Number(config.settings.cubeWidth.value) < 240
        || !config.settings.cubeHeight
        || Number(config.settings.cubeWidth.value) < 240
    ) {
        return {
            height: '240px',
            width: '240px',
        };
    }
    const width = getSettingFromConfigByName(config, EWidgetSettingName.CubeWidth);
    const height = getSettingFromConfigByName(config, EWidgetSettingName.CubeHeight);

    if (config.cubeHeight && config.cubeWidth) {
        return {width: `${String(config.cubeWidth)}px`, height: `${String(config.cubeHeight)}px`};
    }

    return {width: `${width}px`, height: `${height}px`};
};

export const getDataProtectionTextSplitterStyles = (config: IWidgetConfig, props: any) => {
    switch (config.widget_type_id) {
        case EWidgetTypeId.Card:
            return useDataProtectionTextSplitterStylesForCard(props);
        case EWidgetTypeId.Chip:
            return useDataProtectionTextSplitterStylesForChip(props);
        case EWidgetTypeId.Cube:
            return useDataProtectionTextSplitterStylesForCube(props);
        default:
            throw Error('no config or widget type id in config for splitter styles');
    }
};

export const getDataProtectionCheckBoxStyles = (config: IWidgetConfig, props: any) => {
    switch (config.widget_type_id) {
        case EWidgetTypeId.Card:
            return useDataProtectionCheckboxStylesForCard(props);
        case EWidgetTypeId.Chip:
            return useDataProtectionCheckboxStylesForChip(props);
        case EWidgetTypeId.Cube:
            return useDataProtectionCheckboxStylesForCube(props);
        default:
            throw Error('no config or widget type id in config for splitter styles');
    }
};

export const getCubeWidthForSelection = (config: IWidgetConfig) => {
    if (config.cubeWidth) {
        return config.cubeWidth;
    }

    return getSettingFromConfigByName(config, EWidgetSettingName.CubeWidth);
};

export const isBrowserSafari = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf('safari') !== -1) {
        return userAgent.indexOf('chrome') <= -1;
    }

    return false;
};
