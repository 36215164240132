import { PropTypes, Typography } from '@material-ui/core';
import React from 'react';
import { EWidgetSettingName, EWidgetTypeId } from '../../../enums';
import { IWidgetConfig } from '../../../interfaces';
import {
    getDataProtectionTextSplitterStyles,
    getPrivacyCheckNeeded,
    getSettingFromConfigByName,
    getUrl,
} from '../../../utils';
import Alignment = PropTypes.Alignment;

interface IProps {
    config: IWidgetConfig;
    align: Alignment;
    isCubeWidget?: boolean;
}

const DataProtectionTextSplitter = (props: IProps) => {
    const { config, align } = props;
    const isCubeWidget = config.widget_type_id === EWidgetTypeId.Cube;
    const textColor = getSettingFromConfigByName(config, EWidgetSettingName.TextColor);

    // data type casting needed cause API
    const privacyCheckNeeded = getPrivacyCheckNeeded(config);

    // if the privacyCheckIsNotNeeded but there is a privacy_text in the config,
    // then just show the text without the checkbox
    // this is the case if a client of us wants to point to there data
    // protection statement but it is not necessary in their country
    const privacyTextMatch = config.privacy_text.match(/%.*%/);
    const privacyTextSplit = config.privacy_text.split(/%.*%/);

    if (!privacyCheckNeeded) {
        if (privacyTextMatch === null && config.privacy_text) {
            return <Typography>{config.privacy_text}</Typography>;
        }

        return null;
    }

    const classes = getDataProtectionTextSplitterStyles(config, props);

    const body = (privacyTextMatch && privacyTextMatch[0])
        ? privacyTextMatch[0].replace(/%+/, '').replace(/%+/, '')
        : '';

    return (
        <Typography variant={props.isCubeWidget ? 'caption' : 'body2'} align={align} className={classes.typography}>
            {privacyTextSplit[0]}
            <a
                target='_blank'
                rel='noopener noreferrer'
                href={getUrl(config.privacy_link)}
                className={classes.privacyLink}
                style={{ color: isCubeWidget ? textColor : undefined }}
            >
                {body}
            </a>
            {privacyTextSplit[1]}
        </Typography>
    );
};

export default DataProtectionTextSplitter;
