import React, {FC, useEffect, useState} from 'react';
import {useNotificatonBubbleStyles} from '../widgetChip.styles';

export interface INotificationBubbleProps {
    enabled: boolean;
    clickedWidget: boolean;
    configMode: boolean;
}

const NotificationBubble: FC<INotificationBubbleProps> = (props) => {

    const {enabled, clickedWidget, configMode} = props;
    const [showBubble, setShowBubble] = useState(false);

    const classes = useNotificatonBubbleStyles(props);

    useEffect(() => {

        if (configMode && enabled && !showBubble) {
            setShowBubble(true);
            return;
        }

        if (configMode && !enabled && showBubble) {
            setShowBubble(false);
            return;
        }

        let to;
        if (enabled && !clickedWidget) {
            to = setTimeout(() => setShowBubble(true), 5000);
        }

        return () => {
            if (to) {
                clearTimeout(to);
            }
        };
    }, [enabled, clickedWidget, configMode, showBubble]);

    useEffect(() => {
        if (clickedWidget) {
            setShowBubble(false);
        }
    }, [clickedWidget]);

    return (
        <div className={classes.bubbleContainer}>
            <span className={showBubble ? classes.notificationBubbleVisible : classes.notificationBubbleHidden} />
        </div>
    );
};

export default NotificationBubble;
