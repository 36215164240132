import Typography from '@material-ui/core/Typography';
import React from 'react';
import {openCopyRightUrl} from '../../../../../utils';
import {useWidgetCardCopyrightStyles} from '../../../widgetCard.styles';

const WidgetCardCopyright = () => {
    const classes = useWidgetCardCopyrightStyles({});

    return (
        <Typography
            className={classes.copyright}
            onClick={openCopyRightUrl}
        >© MessengerPeople</Typography>
    );
};

export default WidgetCardCopyright;
