import React from 'react';
import Typography from '@material-ui/core/Typography';
import Copyright from '@material-ui/icons/Copyright';
import {IWidgetConfig} from '../../interfaces';
import {isWidgetChipConfigValid, openCopyRightUrl} from '../../utils';
import WidgetChipSpeedDial from './components/WidgetChipSpeedDial';
import {useWidgetChipStyles} from './widgetChip.styles';

export interface IChipWidgetProps {
    config: IWidgetConfig;
    configMode: boolean;
}

const WidgetChip = (props: IChipWidgetProps) => {
    const {config, configMode} = props;

    const classes = useWidgetChipStyles(props);
    const isValid = isWidgetChipConfigValid(config);

    if (!isValid) {
        return null;
    }

    return (
        <div className={classes.chipWidget}>
            <WidgetChipSpeedDial
                config={config}
                configMode={configMode}
                notificationBubble={Boolean(config.settings?.notificationBubble?.value)}
            />
            <div
                className={classes.copyright}
                onClick={openCopyRightUrl}
            >
                <Typography
                    variant={'caption'}
                    className={classes.copyrightText}
                >
                    <Copyright style={{fontSize: 10}} /> MessengerPeople
                </Typography>
            </div>
        </div>
    );
};

export default React.memo(WidgetChip);
