import React, {useState} from 'react';
import {EWidgetSettingName} from '../../enums';
import {IWidgetConfig} from '../../interfaces';
import {getSettingFromConfigByName, getWidthAndHeightForCube} from '../../utils';
import {useWidgetCubeStyles} from './widgetCube.styles';
import WidgetCubeForwardingPage from './WidgetCubeForwardingPage';
import WidgetCubeSelectionPage from './WidgetCubeSelectionPage/WidgetCubeSelectionPage';

const WidgetCube: React.FC<{ config: IWidgetConfig, styleFromProps: any }> = (props) => {
    const {config} = props;
    const [selectedMessenger, setSelectedMessenger] =
        useState(props.config.selectedMessenger ? props.config.selectedMessenger : null);
    const backgroundColor = getSettingFromConfigByName(config, EWidgetSettingName.BackgroundColorLeft);
    const textColor = getSettingFromConfigByName(config, EWidgetSettingName.TextColor);
    const widthAndHeight = getWidthAndHeightForCube(config);
    const classes = useWidgetCubeStyles(props);

    // for preview
    return (
        <div style={{
            ...widthAndHeight,
            color: textColor,
            backgroundColor,
        }}
             className={classes.cube}
        >
            {
                selectedMessenger === null
                    ? <WidgetCubeSelectionPage
                        config={config}
                        setSelectedMessenger={setSelectedMessenger}
                    />
                    : <WidgetCubeForwardingPage
                        config={config}
                        setSelectedMessenger={setSelectedMessenger}
                        selectedMessenger={selectedMessenger}
                    />
            }
        </div>
    );

};

export default WidgetCube;
