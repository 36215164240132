import makeStyles from '@material-ui/core/styles/makeStyles';
import {createStyles} from '@material-ui/styles';
import {ECustomColor} from '../../enums';

const link = {
    privacyLink: {
        '&:link': {
            color: ECustomColor.Link,
            fontWeight: 500,
        },
        '&:visited': {
            color: ECustomColor.LinkVisited,
        },
    },
};

export const useDataProtectionTextSplitterStylesForChip = makeStyles(() => createStyles({
    typography: {
        flex: 1,
        margin: '12px 12px 0 8px',
    },
    ...link,
}));

export const useDataProtectionTextSplitterStylesForCard = makeStyles(() => createStyles({
    typography: {
        hyphens: 'auto',
        paddingRight: 8,
        '-ms-hyphens': 'auto',
        wordWrap: 'break-word',
        '-webkit-hyphens': 'auto',
        overflowWrap: 'break-word',
        marginTop: '16px !important',
    },
    ...link,
}));

export const useDataProtectionTextSplitterStylesForCube = makeStyles(() => createStyles({
    typography: {
        fontSize: 12,
        marginTop: 4,
        overflowWrap: 'break-word',
    },
    ...link,
}));

export const useDataProtectionCheckboxStylesForChip = makeStyles(() => createStyles({
    checkboxContainer: {
        margin: '10px 0 8px 12px',
    },
    checkboxSVG: {
        cursor: 'pointer',
    },
}));

export const useDataProtectionCheckboxStylesForCard = makeStyles(() => createStyles({
    checkboxContainer: {
        paddingTop: 12,
    },
    checkboxSVG: {
        cursor: 'pointer',
    },
}));

export const useDataProtectionCheckboxStylesForCube = makeStyles(() => createStyles({
    checkboxContainer: {
        fontSize: 20,
        paddingTop: 6,
        marginRight: 4,
    },
    checkboxSVG: {
        cursor: 'pointer',
    },
}));
