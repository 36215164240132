import {SvgIcon} from '@material-ui/core';
import * as React from 'react';
import {WHITE} from '../../constants';
import {ICustomIconProps} from '../../interfaces';

const IMessageIcon = (props: ICustomIconProps) => {
    return (
        <SvgIcon
            className={props.className}
            viewBox='0 0 22 22'
        >
            {
                props.chipWidget
                    ? <circle cx={11} cy={11} r={11} fill={props.color}/>
                    : null
            }
            <circle cx={12} cy={12} r={8} fill={WHITE}/>
            <path
                style={{zIndex: 999}}
                transform={'translate(-1 -1)'}
                width={'100%'}
                height={'auto'}
                fill={props.color}
                d='M12,22.5c-5.799,0 -10.5,-4.701 -10.5,-10.5c0,-5.799 4.701,-10.5 10.5,-10.5c5.799,0 10.5,4.701 10.5,10.5c0,5.799 -4.701,10.5 -10.5,10.5Zm-0.018,-6.637c2.978,0 5.392,-1.949 5.392,-4.353c0,-2.405 -2.414,-4.354 -5.392,-4.354c-2.978,0 -5.392,1.949 -5.392,4.354c0,1.468 0.901,2.767 2.281,3.556c0.003,0.002 0.015,0.008 0.035,0.02l0,0c0.018,0.01 0.031,0.027 0.035,0.047c0.119,0.567 -0.658,1.076 -1.177,1.415c1.72,0.109 2.588,-0.58 2.837,-0.806c0.421,0.085 0.927,0.121 1.381,0.121Z'
            />
        </SvgIcon>
    );
};

export default React.memo(IMessageIcon);
