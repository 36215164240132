import {Paper} from '@material-ui/core';
import React from 'react';
import {IWidgetConfig} from '../../../interfaces';
import {TCheckBoxEventFunction} from '../../../types';
import DataProtectionStatement from '../../DataProtectionStatement/DataProtectionStatement';
import {useSpeedDialDataProtectionStatementStyles} from '../widgetChip.styles';

export interface IChipDataProtectionStatementProps {
    chipOpen?: boolean;
    config: IWidgetConfig;
    checkboxChecked: boolean;
    onChange: TCheckBoxEventFunction;
    configMode: boolean;
}

const ChipDataProtectionStatement = (props: IChipDataProtectionStatementProps) => {
    const {config, checkboxChecked, onChange} = props;

    const classes = useSpeedDialDataProtectionStatementStyles(props);

    return (
        <Paper className={classes.formControlPaper}>
            <DataProtectionStatement
                config={config}
                checkboxChecked={checkboxChecked}
                onChange={onChange}
                align={'center'}
            />
        </Paper>
    );
};

export default ChipDataProtectionStatement;
