import Paper from '@material-ui/core/Paper';
import React, {useEffect, useState} from 'react';
import {v4 as uuidv4} from 'uuid';
import {EWidgetCardCustomBreakpoint} from '../../enums/component.enums';
import {IWidgetCardProps} from '../../interfaces';
import {getInitialMessenger, getWidgetCardCustomBreakpoints, isWidgetCardConfigValid} from '../../utils';
import WidgetCardContent from './components/WidgetCardContent/WidgetCardContent';
import WidgetCardMessenger from './components/WidgetCardMessenger/WidgetCardMessenger';
import EmptyCardWidget from './EmptyCardWidget';
import {useWidgetCardStyles} from './widgetCard.styles';

export const WIDGET_CARD_PAPER_ID = uuidv4();

const WidgetCard = (props: IWidgetCardProps) => {
    const {config} = props;
    const classes = useWidgetCardStyles(props);
    const initialMessenger = getInitialMessenger(props.config);

    const [selectedMessenger, setSelectedMessenger] = useState(initialMessenger);
    const [messengerContainerBreakpoint, setMessengerContainerBreakpoint] = useState(EWidgetCardCustomBreakpoint.Large);

    useEffect(() => {
        const breakpoint = getWidgetCardCustomBreakpoints(WIDGET_CARD_PAPER_ID);
        if (breakpoint === false) {
            return;
        }

        setMessengerContainerBreakpoint(breakpoint);
    });

    const paperClass = messengerContainerBreakpoint === EWidgetCardCustomBreakpoint.ExtraSmall
        ? classes.paperExtraSmall
        : classes.paper;

    if (!isWidgetCardConfigValid(config)) {
        return <EmptyCardWidget />;
    }

    return (
        <Paper className={paperClass} id={WIDGET_CARD_PAPER_ID} color={'inherit'}>
            <WidgetCardMessenger
                config={config}
                selectedMessenger={selectedMessenger}
                setSelectedMessenger={setSelectedMessenger}
                messengerContainerBreakpoint={messengerContainerBreakpoint}
            />
            <WidgetCardContent
                config={config}
                channelName={props.channelName}
                selectedMessenger={selectedMessenger}
            />
        </Paper>
    );
};

export default WidgetCard;
