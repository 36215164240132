import {Grid} from '@material-ui/core';
import React from 'react';
import {useState} from 'react';
import {CHANNEL_TO_REPLACE} from '../../../../constants';
import {IWidgetCardContentContainerProps} from '../../../../interfaces';
import {TCheckBoxEventFunction} from '../../../../types';
import {getButtonText, getDisabled, getWidgetCardCustomBreakpoints} from '../../../../utils';
import DataProtectionStatement from '../../../DataProtectionStatement/DataProtectionStatement';
import {WIDGET_CARD_PAPER_ID} from '../../WidgetCard';
import WidgetCardButton from './components/WidgetCardButton';
import WidgetCardContentContainer from './components/WidgetCardContentContainer';
import WidgetCardCopyright from './components/WidgetCardCopyright';
import WidgetCardTextContainer from './components/WidgetCardTextContainer';

const WidgetCardContent = (props: IWidgetCardContentContainerProps) => {
    const {config, selectedMessenger, channelName} = props;
    const [checkboxChecked, setCheckboxChecked] = useState(false);
    const onChange: TCheckBoxEventFunction = (event, checked) => setCheckboxChecked(checked);
    const buttonText = getButtonText(config, selectedMessenger);
    const buttonDisabled = getDisabled(config, checkboxChecked);

    const body = config.body
        ? config.body.replace(CHANNEL_TO_REPLACE, channelName)
        : '';

    return (
        <WidgetCardContentContainer>
            <WidgetCardTextContainer
                title={config.title}
                body={body}
                channelName={channelName}
            />
            <DataProtectionStatement
                config={config}
                align={'left'}
                checkboxChecked={checkboxChecked}
                onChange={onChange}
            />
            <Grid container>
                <Grid item xs={12} style={{margin: '8px 0'}}>
                    <WidgetCardButton
                        breakpoint={getWidgetCardCustomBreakpoints(WIDGET_CARD_PAPER_ID)}
                        selectedMessenger={config.messengers[selectedMessenger]}
                        uniqueId={config.uniqueid}
                        disabled={buttonDisabled}
                        buttonText={buttonText}
                        config={config}
                    />
                </Grid>
                <Grid item xs={12}>
                    <WidgetCardCopyright/>
                </Grid>
            </Grid>
        </WidgetCardContentContainer>
    );
};

export default WidgetCardContent;
