import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, {useState} from 'react';
import {EMessengerId, EPrivacyCheck, EWidgetSettingName} from '../../enums';
import {EWidgetCardCustomBreakpoint} from '../../enums/component.enums';
import {IMessenger, IWidgetConfig} from '../../interfaces';
import {getIconClassName, getSettingFromConfigByName, redirectMessenger} from '../../utils';
import DataProtectionStatement from '../DataProtectionStatement/DataProtectionStatement';
import MessengerIcon from '../Icons/MessengerIcon';
import {useWidgetCardMessengerIconStyles} from '../WidgetCard/widgetCard.styles';
import {useWidgetCubeForwardingPageStyle} from './widgetCube.styles';

interface IProps {
    config: IWidgetConfig;
    selectedMessenger: EMessengerId;
    setSelectedMessenger: (params: any) => void;
}

const getButtonColor = (disabled: boolean, config: IWidgetConfig, selectedMessenger: IMessenger) => {
    const customButtonColorEnabled = getSettingFromConfigByName(config, EWidgetSettingName.CustomButtonColorEnabled);
    const backgroundColorButton = getSettingFromConfigByName(config, EWidgetSettingName.ButtonColor);

    if (disabled) {
        return undefined;
    }

    if (customButtonColorEnabled) {
        return backgroundColorButton;
    }

    return selectedMessenger.color;
};

const WidgetCubeForwardingPage: React.FC<IProps> = (props) => {
    const {config} = props;
    const privacyCheckActive = props.config.privacy_check === EPrivacyCheck.Enabled;
    const [checked, setChecked] = useState(false);
    const backgroundColor = getSettingFromConfigByName(config, EWidgetSettingName.BackgroundColorRight);
    const buttonTextColor = getSettingFromConfigByName(config, EWidgetSettingName.ButtonTextColor);
    const disabled = privacyCheckActive ? !checked : false;
    const classesForIcon = useWidgetCardMessengerIconStyles(props);
    const iconClass = getIconClassName(EWidgetCardCustomBreakpoint.Large, classesForIcon);
    const selectedMessenger = props.config.messengers[props.selectedMessenger];
    const buttonText = config.messengers[props.selectedMessenger].button_text[config.language];
    const classes = useWidgetCubeForwardingPageStyle(props);
    const buttonColor = getButtonColor(disabled, config, selectedMessenger);

    return (
        <>
            <div
                style={{
                    backgroundColor,
                }}
                className={classes.container}
            >
                <ArrowBackIcon
                    fontSize={'small'}
                    className={classes.arrow}
                    onClick={() => props.setSelectedMessenger(null)}
                />
                <div className={classes.icon}>
                    <MessengerIcon
                        disabled={false}
                        className={iconClass}
                        chipWidget={false}
                        config={props.config}
                        messenger={props.config.messengers[props.selectedMessenger]}
                    />
                </div>
                <Typography
                    className={classes.typo}
                    align={'center'}
                >
                    {selectedMessenger.body}
                </Typography>
                <div className={classes.protectionStatementContainer}>
                    {
                        !privacyCheckActive
                            ? null
                            : <div className={classes.protectionStatement}>
                                <DataProtectionStatement
                                    config={config}
                                    checkboxChecked={checked}
                                    onChange={() => setChecked(!checked)} align={'left'}
                                />
                            </div>
                    }
                    <div className={classes.buttonContainer}>
                        <Button
                            style={{
                                color: buttonTextColor,
                                backgroundColor: buttonColor,
                            }}
                            className={classes.button}
                            onClick={() => redirectMessenger(config.messengers[props.selectedMessenger], config)}
                            variant="contained"
                            disabled={disabled}
                        >
                            {buttonText}
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default WidgetCubeForwardingPage;
