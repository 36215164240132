import * as React from 'react';
import {useWidgetCardContentContainerStyles} from '../../../widgetCard.styles';

interface IProps {
   children: React.ReactNode;
}

const WidgetCardContentContainer = (props: IProps) => {
    const classes = useWidgetCardContentContainerStyles(props);

    return (
        <div className={classes.contentContainer}>
            {props.children}
        </div>
    );
};

export default React.memo(WidgetCardContentContainer);
