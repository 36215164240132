import SvgIcon from '@material-ui/core/SvgIcon/SvgIcon';
import React from 'react';
import {DISABLED_GREY} from '../../constants';
import {APPLE_BLUE} from '../../constants/const';
import {EMessengerId} from '../../enums';
import {IConfig, IMessenger} from '../../interfaces';
import {isChipWidget, renderRectangle} from '../../utils';
import IMessageIcon from './IMessageIcon';
import FbMessengerIcon from './messengerIcon/FbMessengerIcon';
import InstagramIcon from './messengerIcon/InstagramIcon';
import {NotifyIcon} from './messengerIcon/NotifyIcon';

interface IProps extends IConfig {
    disabled: boolean;
    messenger: IMessenger;
    className: string;
    chipWidget: boolean;
}

const messengerSVGPath = {
    FB: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm0,5A7.08,7.08,0,0,0,4.7,11.8a6.65,6.65,0,0,0,2.71,5.3v2.61L9.9,18.33a7.66,7.66,0,0,0,2.1.29,7.08,7.08,0,0,0,7.3-6.82A7.08,7.08,0,0,0,12,5Zm.77,9.15-1.89-2-3.63,2,4-4.23,1.89,2,3.63-2Z',
    TG: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0ZM8,13.24l-2.82-.92h0a.64.64,0,0,1-.4-.8A.74.74,0,0,1,5,11.15C5.7,10.69,15.94,7,17.11,6.58a1.25,1.25,0,0,1,.7,0,.54.54,0,0,1,.35.37,1.17,1.17,0,0,1,0,.35.81.81,0,0,1,0,.16.45.45,0,0,1,0,.11C18.11,8.67,16.12,16.7,16,17a.93.93,0,0,1-.83.71h0a1.07,1.07,0,0,1-.74-.3c-.43-.37-1.41-1.07-2.36-1.72-.33.28-.84.73-1.36,1.25a1.35,1.35,0,0,1-.79.48.41.41,0,0,1-.19,0l-.05,0a1.13,1.13,0,0,1-.38-.46v0Zm2.46,1.36a.15.15,0,0,0-.13,0,.12.12,0,0,0-.08.11c0,.42-.12,1.31-.17,1.81a.12.12,0,0,0,.08.13.12.12,0,0,0,.15,0c.35-.31.94-.86,1.2-1.1a.13.13,0,0,0,0-.11.13.13,0,0,0-.06-.1Zm-.68-.71a.5.5,0,0,1,.1-.24s2.28-2.05,3.65-3.33a.1.1,0,0,0-.13-.16C12.11,10.93,10.45,12,8.85,13a.48.48,0,0,0-.19.57l.67,1.86a.15.15,0,0,0,.15.09.15.15,0,0,0,.12-.13l.13-1.47h0Z',
    TC: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0ZM8,13.24l-2.82-.92h0a.64.64,0,0,1-.4-.8A.74.74,0,0,1,5,11.15C5.7,10.69,15.94,7,17.11,6.58a1.25,1.25,0,0,1,.7,0,.54.54,0,0,1,.35.37,1.17,1.17,0,0,1,0,.35.81.81,0,0,1,0,.16.45.45,0,0,1,0,.11C18.11,8.67,16.12,16.7,16,17a.93.93,0,0,1-.83.71h0a1.07,1.07,0,0,1-.74-.3c-.43-.37-1.41-1.07-2.36-1.72-.33.28-.84.73-1.36,1.25a1.35,1.35,0,0,1-.79.48.41.41,0,0,1-.19,0l-.05,0a1.13,1.13,0,0,1-.38-.46v0Zm2.46,1.36a.15.15,0,0,0-.13,0,.12.12,0,0,0-.08.11c0,.42-.12,1.31-.17,1.81a.12.12,0,0,0,.08.13.12.12,0,0,0,.15,0c.35-.31.94-.86,1.2-1.1a.13.13,0,0,0,0-.11.13.13,0,0,0-.06-.1Zm-.68-.71a.5.5,0,0,1,.1-.24s2.28-2.05,3.65-3.33a.1.1,0,0,0-.13-.16C12.11,10.93,10.45,12,8.85,13a.48.48,0,0,0-.19.57l.67,1.86a.15.15,0,0,0,.15.09.15.15,0,0,0,.12-.13l.13-1.47h0Z',
    WN: 'M416.748,1140.6l-149.562,0c-137.576,0 -249.27,-111.694 -249.27,-249.27l0,-498.539c0,-137.575 111.694,-249.269 249.27,-249.27l152.634,0l125.51,251.021c7.755,15.51 25.157,23.653 42.034,19.669c16.877,-3.985 28.8,-19.05 28.8,-36.391l0,-234.299l118.178,0c-1.073,9.152 -1.625,18.462 -1.625,27.9c0,130.993 106.349,237.342 237.341,237.342c15.362,0 30.385,-1.462 44.937,-4.256l0,486.823c0,137.576 -111.693,249.269 -249.27,249.27l-152.633,0l-125.511,-251.02c-7.755,-15.51 -25.156,-23.653 -42.033,-19.669c-16.877,3.984 -28.8,19.049 -28.8,36.39l0,234.299Z',
    IM: 'M200,25c96.585,0 175,78.415 175,175c0,96.585 -78.415,175 -175,175c-96.585,0 -175,-78.415 -175,-175c0,-96.585 78.415,-175 175,-175Zm-61.216,253.232c-34.076,-17.05 -56.856,-47.893 -56.856,-83.08c0,-53.623 52.906,-97.159 118.072,-97.159c65.166,0 118.072,43.536 118.072,97.159c0,53.624 -52.906,97.16 -118.072,97.16c-11.001,0 -21.653,-1.241 -31.76,-3.562c-10.382,11.137 -29.75,18.764 -46.93,18.423c10.008,-4.802 15.54,-14.788 17.474,-28.941Z',
    VI: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0ZM8.3,17.6c-3.54-1-3.49-4.67-3.44-6.63s.41-3.54,1.49-4.62c1.94-1.78,5.92-1.54,5.92-1.54,3.38,0,4.89,1.12,5.27,1.46,1.24,1.08,1.93,3.48,1.47,7.26a4.43,4.43,0,0,1-3.71,4.18,13.39,13.39,0,0,1-4.49.33c-.27.31-.62.72-.88,1-.47.5-.75,1-1.24.82s-.39-.74-.39-.74V17.6ZM11.72,5.86c-1,0-3.27.14-4.53,1.28A5.37,5.37,0,0,0,5.94,11c0,1.63-.21,4.76,2.78,5.59V17c0,.66,0,2,0,2.16s0,.24.1.26A.28.28,0,0,0,9,19.31c.48-.49,2-2.33,2-2.33a12.55,12.55,0,0,0,3.86-.33,3.52,3.52,0,0,0,3-3.39c.39-3.16-.15-5.32-1.2-6.23-.31-.28-1.68-1.14-4.54-1.15a4.36,4.36,0,0,0-.46,0Zm.84,7.58a.5.5,0,0,0,.41-.15l.28-.36a.65.65,0,0,1,.79-.11c.18.11.5.3.7.45l.65.52a.54.54,0,0,1,.11.71h0a2.63,2.63,0,0,1-.59.72h0a1.17,1.17,0,0,1-.6.28h-.08a.69.69,0,0,1-.25,0h0a8.6,8.6,0,0,1-1.64-.76,9.3,9.3,0,0,1-1.37-.9,7.44,7.44,0,0,1-.62-.55l-.08-.08a7.44,7.44,0,0,1-.55-.62,10.16,10.16,0,0,1-.91-1.37,8.33,8.33,0,0,1-.75-1.64H8a.73.73,0,0,1,0-.25V9.2a1.2,1.2,0,0,1,.29-.59h0A2.78,2.78,0,0,1,9,8H9a.55.55,0,0,1,.71.12l.52.65c.14.2.34.52.44.7a.6.6,0,0,1-.11.78l-.35.28a.55.55,0,0,0-.16.42A3.7,3.7,0,0,0,12.56,13.44Zm3.09-1.64a.18.18,0,0,0,.18-.2,4,4,0,0,0-1.14-3A3.91,3.91,0,0,0,11.9,7.5a.19.19,0,0,0-.2.19.19.19,0,0,0,.19.19,3.6,3.6,0,0,1,2.54,1,3.72,3.72,0,0,1,1,2.73.2.2,0,0,0,.19.19Zm-1-.39h0a.2.2,0,0,1-.19-.2,2.16,2.16,0,0,0-.57-1.61,2.34,2.34,0,0,0-1.66-.73.18.18,0,0,1-.18-.2.2.2,0,0,1,.2-.18,2.75,2.75,0,0,1,1.92.85,2.58,2.58,0,0,1,.67,1.88A.2.2,0,0,1,14.64,11.41Zm-1-.33a.19.19,0,0,1-.19-.18.9.9,0,0,0-1-1,.2.2,0,0,1-.18-.2.19.19,0,0,1,.2-.18,1.27,1.27,0,0,1,1.32,1.36.19.19,0,0,1-.18.2Z',
    WA: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm5.05,6.91A7.11,7.11,0,0,0,5.89,15.49l-1,3.68,3.76-1a7.15,7.15,0,0,0,3.39.87h0a7.11,7.11,0,0,0,5-12.13Zm-5,10.93h0A5.87,5.87,0,0,1,9,17l-.22-.12-2.23.58.6-2.18-.14-.22a5.89,5.89,0,0,1,5-9,5.91,5.91,0,0,1,5.9,5.9A5.91,5.91,0,0,1,12.05,17.85Zm3.23-4.42c-.17-.09-1-.52-1.21-.58s-.28-.09-.4.09-.46.57-.56.69-.21.14-.38,0a5,5,0,0,1-1.43-.88,5.44,5.44,0,0,1-1-1.23.26.26,0,0,1,.08-.36c.08-.08.18-.21.27-.32a1.38,1.38,0,0,0,.17-.29.34.34,0,0,0,0-.31c-.05-.09-.4-1-.55-1.32s-.29-.3-.4-.3H9.53a.63.63,0,0,0-.47.22,2,2,0,0,0-.62,1.48,3.45,3.45,0,0,0,.72,1.84,7.91,7.91,0,0,0,3,2.68c.43.18.76.29,1,.37a2.33,2.33,0,0,0,1.12.07,1.85,1.85,0,0,0,1.2-.84,1.42,1.42,0,0,0,.1-.85C15.58,13.56,15.46,13.51,15.28,13.43Z',
    WB: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm5.05,6.91A7.11,7.11,0,0,0,5.89,15.49l-1,3.68,3.76-1a7.15,7.15,0,0,0,3.39.87h0a7.11,7.11,0,0,0,5-12.13Zm-5,10.93h0A5.87,5.87,0,0,1,9,17l-.22-.12-2.23.58.6-2.18-.14-.22a5.89,5.89,0,0,1,5-9,5.91,5.91,0,0,1,5.9,5.9A5.91,5.91,0,0,1,12.05,17.85Zm3.23-4.42c-.17-.09-1-.52-1.21-.58s-.28-.09-.4.09-.46.57-.56.69-.21.14-.38,0a5,5,0,0,1-1.43-.88,5.44,5.44,0,0,1-1-1.23.26.26,0,0,1,.08-.36c.08-.08.18-.21.27-.32a1.38,1.38,0,0,0,.17-.29.34.34,0,0,0,0-.31c-.05-.09-.4-1-.55-1.32s-.29-.3-.4-.3H9.53a.63.63,0,0,0-.47.22,2,2,0,0,0-.62,1.48,3.45,3.45,0,0,0,.72,1.84,7.91,7.91,0,0,0,3,2.68c.43.18.76.29,1,.37a2.33,2.33,0,0,0,1.12.07,1.85,1.85,0,0,0,1.2-.84,1.42,1.42,0,0,0,.1-.85C15.58,13.56,15.46,13.51,15.28,13.43Z',
    CC: 'M12,0A12,12,0,1,1,0,12,12,12,0,0,1,12,0Zm5.05,6.91A7.11,7.11,0,0,0,5.89,15.49l-1,3.68,3.76-1a7.15,7.15,0,0,0,3.39.87h0a7.11,7.11,0,0,0,5-12.13Zm-5,10.93h0A5.87,5.87,0,0,1,9,17l-.22-.12-2.23.58.6-2.18-.14-.22a5.89,5.89,0,0,1,5-9,5.91,5.91,0,0,1,5.9,5.9A5.91,5.91,0,0,1,12.05,17.85Zm3.23-4.42c-.17-.09-1-.52-1.21-.58s-.28-.09-.4.09-.46.57-.56.69-.21.14-.38,0a5,5,0,0,1-1.43-.88,5.44,5.44,0,0,1-1-1.23.26.26,0,0,1,.08-.36c.08-.08.18-.21.27-.32a1.38,1.38,0,0,0,.17-.29.34.34,0,0,0,0-.31c-.05-.09-.4-1-.55-1.32s-.29-.3-.4-.3H9.53a.63.63,0,0,0-.47.22,2,2,0,0,0-.62,1.48,3.45,3.45,0,0,0,.72,1.84,7.91,7.91,0,0,0,3,2.68c.43.18.76.29,1,.37a2.33,2.33,0,0,0,1.12.07,1.85,1.85,0,0,0,1.2-.84,1.42,1.42,0,0,0,.1-.85C15.58,13.56,15.46,13.51,15.28,13.43Z',
};

const MessengerIcon = (props: IProps) => {
    const {disabled, className, chipWidget, messenger} = props;
    let color = disabled ? DISABLED_GREY : messenger.color;

    if (messenger.id === EMessengerId.Instagram) {
        return <InstagramIcon className={className} disabled={disabled} />;
    }

    if (messenger.id === EMessengerId.InstaAndNotify) {
        color = props.disabled ? DISABLED_GREY : '#0076BA';

        return <NotifyIcon
            chipWidget={chipWidget}
            className={className}
            color={color}
        />;
    }

    if (messenger.id === EMessengerId.IMessage) {
        const buttonColor =
            isChipWidget(props.config)
            ? APPLE_BLUE
            : props.config.settings.buttonColor.value;

        color = props.disabled ? DISABLED_GREY : buttonColor;

        return <IMessageIcon
            chipWidget={chipWidget}
            color={color}
            className={className}
        />;
    }

    if (messenger.id === EMessengerId.Facebook) {
        return <FbMessengerIcon disabled={props.disabled} className={className} />;
    }

    const path = messengerSVGPath[messenger.id];

    return (
        <SvgIcon className={className}>
            <g fill="none" fillRule="evenodd">
                {renderRectangle(messenger.id)}
                <path fill={color} d={path} />
            </g>
        </SvgIcon>
    );
};

export default MessengerIcon;
