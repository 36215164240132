import {createStyles, makeStyles, Theme} from '@material-ui/core';
import {ECustomColor, EWidgetSettingName} from '../../enums';
import {getWidgetSettingValue} from '../../utils';
import {IChipWidgetProps} from './WidgetChip';
import {IChipSpeedDialProps} from './components/WidgetChipSpeedDial';
import {IChipDataProtectionStatementProps} from './components/ChipDataProtectionStatement';

const CHIP_ICON_COLOR_DEFAULT = '#ffffff';
const CHIP_BACKGROUND_COLOR_DEFAULT = '#09C697';
const CHIP_ICON_COLOR_HOVER_DEFAULT = '#000000';
const CHIP_BACKGROUND_COLOR_HOVER_DEFAULT = '#ffffff';

const CHIP_Z_INDEX = 99999;

export const useWidgetChipStyles = makeStyles((theme: Theme) => {
    return createStyles(
        {
            copyright: {
                height: theme.spacing(3),
                cursor: 'pointer',
                fontSize: 10,
                display: 'flex',
                justifyContent: 'flex-end',
            },
            copyrightText: {
                whiteSpace: 'nowrap',
                fontSize: 'inherit',
            },
            chipWidget: {
                position: (props: IChipWidgetProps) => props.configMode ? 'absolute' : 'fixed',
                bottom: (props: IChipWidgetProps) => props.configMode ? -16 : 16,
                maxWidth: () => window.screen.availWidth - 114,
                right: 16,
                zIndex: CHIP_Z_INDEX,
            },
        },
    );
});

export const useSpeedDialDataProtectionStatementStyles = makeStyles(() => createStyles({
    formControlPaper: {
        position: 'relative',
        bottom: -36,
        right: 90,
        alignSelf: 'flex-end',
        alignContent: 'center',
        borderRadius: 24,
        marginBottom: 8,
        display: (props: IChipDataProtectionStatementProps) => (props.chipOpen && Boolean(props.config.privacy_check)) ? 'flex' : 'none',
        maxWidth: (props: IChipDataProtectionStatementProps) => props.configMode ? 430 : undefined,
    },
    checkbox: {
        flex: 1,
        alignSelf: 'center',
    },
}));

const getColor = (props: IChipWidgetProps, settingName: EWidgetSettingName) => {

    const settings = props.config.settings;

    const image = getWidgetSettingValue(settings, EWidgetSettingName.ChipBackgroundImage);

    if (image) {
        return;
    }

    switch (settingName) {
        case EWidgetSettingName.ChipIconColor:
            return getWidgetSettingValue(settings, EWidgetSettingName.ChipIconColor) || CHIP_ICON_COLOR_DEFAULT;
        case EWidgetSettingName.ChipBackgroundColor:
            return getWidgetSettingValue(settings, EWidgetSettingName.ChipBackgroundColor) || CHIP_BACKGROUND_COLOR_DEFAULT;
        case EWidgetSettingName.ChipIconColorHover:
            return getWidgetSettingValue(settings, EWidgetSettingName.ChipIconColorHover) || CHIP_ICON_COLOR_HOVER_DEFAULT;
        case EWidgetSettingName.ChipBackgroundColorHover:
            return getWidgetSettingValue(settings, EWidgetSettingName.ChipBackgroundColorHover) || CHIP_BACKGROUND_COLOR_HOVER_DEFAULT;
        default:
            return;
    }
};

export const useWidgetChipSpeedDialStyles = makeStyles(() => {
    return createStyles(
        {
            speedDial: {
                position: 'absolute',
                bottom: 32,
                right: 16,
            },
            speedDialImage: {
                width: 56,
                height: 56,
                borderRadius: '100%',
                backgroundImage: (props: IChipSpeedDialProps) => {
                    const image = getWidgetSettingValue(props.config.settings, EWidgetSettingName.ChipBackgroundImage);
                    return image ? `url(${image})` : undefined;
                },
                backgroundSize: 'cover',
            },
            seedDialButtonSingleMessenger: {
                fontSize: 24,
                pointerEvents: 'auto',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                    opacity: 0.82,
                },
                '& svg': {
                    verticalAlign: 'baseline !important',
                },
            },
            speedDialButton: {
                fontSize: 24,
                pointerEvents: 'auto',
                transition: 'all 0.2s ease-in-out',
                color: (props: IChipSpeedDialProps) => getColor(props, EWidgetSettingName.ChipIconColor),
                backgroundColor: (props: IChipSpeedDialProps) => getColor(props, EWidgetSettingName.ChipBackgroundColor),
                '&:hover': {
                    opacity: 0.82,
                    color: (props: IChipSpeedDialProps) => getColor(props, EWidgetSettingName.ChipIconColorHover),
                    backgroundColor: (props: IChipSpeedDialProps) => getColor(props, EWidgetSettingName.ChipBackgroundColorHover),
                },
                '& svg': {
                    verticalAlign: 'baseline !important',
                },
            },
            speedDialAction: {
                backgroundColor: ECustomColor.ShadowSurface,

                '&:hover, &:focus, &:active': {
                    backgroundColor: ECustomColor.ShadowSurface,
                },
            },
            icon: {
                maxHeight: 40,
                maxWidth: 40,
                minWidth: 40,
                minHeight: 40,
            },
            iconForSpecialConfig: {
                maxHeight: 60,
                maxWidth: 60,
                minWidth: 60,
                minHeight: 60,
            },
        },
    );
});

export const useNotificatonBubbleStyles = makeStyles((theme) => {
    return createStyles(
        {
            bubbleContainer: {
                display: 'flex',
                height: 40,
                position: 'relative',
                justifyContent: 'flex-end',
                alignItems: 'center',
                bottom: 36,
                left: -16,
            },
            notificationBubbleVisible: {
                backgroundColor: theme.palette.error.main,
                width: 20,
                height: 20,
                zIndex: CHIP_Z_INDEX + 1,
                borderRadius: '100%',
                opacity: 1,
                transform: 'scale(1)',
                transition: 'all 0.4s cubic-bezier(.81,1.95,.59,.65)',
            },
            notificationBubbleHidden: {
                backgroundColor: theme.palette.error.main,
                width: 20,
                height: 20,
                zIndex: CHIP_Z_INDEX + 1,
                borderRadius: '100%',
                opacity: 0,
                transform: 'scale(0)',
                transitionTimingFunction: 'ease-out',
            },
        },
    );
});
