import Button from '@material-ui/core/Button';
import React from 'react';
import {IWidgetCardButtonProps} from '../../../../../interfaces';
import {redirectMessenger} from '../../../../../utils';
import {useWidgetCardButtonStyles} from '../../../widgetCard.styles';

const WidgetCardButton = (props: IWidgetCardButtonProps) => {
    const {selectedMessenger, config, buttonText, disabled} = props;
    const classes = useWidgetCardButtonStyles(props);

    return (
        <Button
            onClick={() => redirectMessenger(selectedMessenger, config)}
            variant="contained"
            className={classes.button}
            disabled={disabled}
        >
            {buttonText}
        </Button>
    );
};

export default WidgetCardButton;
