import Paper from '@material-ui/core/Paper';
import React from 'react';
// @ts-ignore
import MessengerPeopleLogo from '../../../assets/images/logo_icon.png';
import WidgetCardContentContainer from './components/WidgetCardContent/components/WidgetCardContentContainer';
import WidgetCardTextContainer from './components/WidgetCardContent/components/WidgetCardTextContainer';
import WidgetCardMessengerContainer from './components/WidgetCardMessenger/components/WidgetCardMessengerContainer';
import {WIDGET_CARD_PAPER_ID} from './WidgetCard';
import {useWidgetCardStyles} from './widgetCard.styles';

const EmptyCardWidget = () => {
    const classes = useWidgetCardStyles({});

    return (
        <Paper className={classes.emptyWidgetPaper} id={WIDGET_CARD_PAPER_ID} color={'inherit'}>
            <WidgetCardMessengerContainer messengerToShow={[1]}>
                <img src={MessengerPeopleLogo} width={48} height={48} style={{padding: 8}} alt={''}/>
            </WidgetCardMessengerContainer>
            <WidgetCardContentContainer>
                <WidgetCardTextContainer
                    title={'Service Not Available'}
                    channelName={''}
                    body={''}
                />
            </WidgetCardContentContainer>
        </Paper>
    );
};

export default React.memo(EmptyCardWidget);
